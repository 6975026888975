import { repsBookingConstants } from "../constants";
import { repsBookingService } from "../services";

export const repsBookingActions = {
    getHospitalsList,
    getRepsList,
    getKitSetByKit,
    getList,
    add,
    getById,
    getHospitalDetailsById,
    update,
    updateStatus,
    saveReturn,
    delete: _delete
};

function getHospitalsList(search) {
    return dispatch => {
        dispatch(request());

        repsBookingService.getHospitalsList(search)
            .then(
                hospitalsList => dispatch(success(hospitalsList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: repsBookingConstants.GETALLHOSPITALS_REQUEST }; }
    function success(hospitalsList) { return { type: repsBookingConstants.GETALLHOSPITALS_SUCCESS, hospitalsList }; }
    function failure(error) { return { type: repsBookingConstants.GETALLHOSPITALS_FAILURE, error }; }
}

function getRepsList(search) {
    return dispatch => {
        dispatch(request());

        repsBookingService.getRepsList(search)
            .then(
                repsList => dispatch(success(repsList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: repsBookingConstants.GETALLREPS_REQUEST }; }
    function success(repsList) { return { type: repsBookingConstants.GETALLREPS_SUCCESS, repsList }; }
    function failure(error) { return { type: repsBookingConstants.GETALLREPS_FAILURE, error }; }
}

function getKitSetByKit(search) {
    return dispatch => {
        dispatch(request());

        repsBookingService.getKitSetByKit(search)
            .then(
                kitSetList => dispatch(success(kitSetList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: repsBookingConstants.GETKITSETBYKIT_REQUEST }; }
    function success(kitSetList) { return { type: repsBookingConstants.GETKITSETBYKIT_SUCCESS, kitSetList }; }
    function failure(error) { return { type: repsBookingConstants.GETKITSETBYKIT_FAILURE, error }; }
}


function getList(search) {
    return dispatch => {
        dispatch(request());

        repsBookingService.getList(search)
            .then(
                bookingList => dispatch(success(bookingList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: repsBookingConstants.GETALLBOOKINGS_REQUEST }; }
    function success(bookingList) { return { type: repsBookingConstants.GETALLBOOKINGS_SUCCESS, bookingList }; }
    function failure(error) { return { type: repsBookingConstants.GETALLBOOKINGS_FAILURE, error }; }
}

function add(info) {
    return dispatch => {
        dispatch(request());

        repsBookingService.add(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: repsBookingConstants.BOOKINGADD_REQUEST }; }
    function success(info) { return { type: repsBookingConstants.BOOKINGADD_SUCCESS, info }; }
    function failure(error) { return { type: repsBookingConstants.BOOKINGADD_FAILURE, error }; }
}

function getById(id) {
    return dispatch => {
        dispatch(request());

        repsBookingService.getById(id)
            .then(
                bookingInfo => dispatch(success(bookingInfo)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: repsBookingConstants.BOOKINGEDIT_REQUEST }; }
    function success(bookingInfo) { return { type: repsBookingConstants.BOOKINGEDIT_SUCCESS, bookingInfo }; }
    function failure(error) { return { type: repsBookingConstants.BOOKINGEDIT_FAILURE, error }; }
}

function getHospitalDetailsById(id) {
    return dispatch => {
        dispatch(request());

        repsBookingService.getHospitalDetailsById(id)
            .then(
                hospitalDetails => dispatch(success(hospitalDetails)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: repsBookingConstants.GETHOSPITALADDRESS_REQUEST }; }
    function success(hospitalDetails) { return { type: repsBookingConstants.GETHOSPITALADDRESS_SUCCESS, hospitalDetails }; }
    function failure(error) { return { type: repsBookingConstants.GETHOSPITALADDRESS_FAILURE, error }; }
}

function update(info) {
    return dispatch => {
        dispatch(request());

        repsBookingService.update(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: repsBookingConstants.BOOKINGUPDATE_REQUEST }; }
    function success(info) { return { type: repsBookingConstants.BOOKINGUPDATE_SUCCESS, info }; }
    function failure(error) { return { type: repsBookingConstants.BOOKINGUPDATE_FAILURE, error }; }
}

function updateStatus(info) {
    return dispatch => {
        dispatch(request());

        repsBookingService.updateStatus(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: repsBookingConstants.BOOKINGSTATUSUPDATE_REQUEST }; }
    function success(info) { return { type: repsBookingConstants.BOOKINGSTATUSUPDATE_SUCCESS, info }; }
    function failure(error) { return { type: repsBookingConstants.BOOKINGSTATUSUPDATE_FAILURE, error }; }
}

function saveReturn(returnInfo) {
    return dispatch => {
        dispatch(request());

        repsBookingService.saveReturn(returnInfo)
            .then(
                returnInfo => dispatch(success(returnInfo)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: repsBookingConstants.SETRETURN_REQUEST }; }
    function success(returnInfo) { return { type: repsBookingConstants.SETRETURN_SUCCESS, returnInfo }; }
    function failure(error) { return { type: repsBookingConstants.SETRETURN_FAILURE, error }; }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        repsBookingService.delete(id)
            .then(
                kit => { 
                    console.log(kit);
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: repsBookingConstants.DELETE_REQUEST, id }; }
    function success(id) { return { type: repsBookingConstants.DELETE_SUCCESS, id }; }
    function failure(id, error) { return { type: repsBookingConstants.DELETE_FAILURE, id, error }; }
}