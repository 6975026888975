export const repsBookingConstants = {
    GETALLHOSPITALS_REQUEST: "HOSPITALS_LIST_REQUEST",
    GETALLHOSPITALS_SUCCESS: "HOSPITALS_LIST_SUCCESS",
    GETALLHOSPITALS_FAILURE: "HOSPITALS_LIST_FAILURE",

    GETALLREPS_REQUEST: "REPS_LIST_REQUEST",
    GETALLREPS_SUCCESS: "REPS_LIST_SUCCESS",
    GETALLREPS_FAILURE: "REPS_LIST_FAILURE",

    GETKITSETBYKIT_REQUEST: "KITSETBYKIT_LIST_REQUEST",
    GETKITSETBYKIT_SUCCESS: "KITSETBYKIT_LIST_SUCCESS",
    GETKITSETBYKIT_FAILURE: "KITSETBYKIT_LIST_FAILURE",

    BOOKINGADD_REQUEST: "BOOKING_ADD_REQUEST",
    BOOKINGADD_SUCCESS: "BOOKING_ADD_SUCCESS",
    BOOKINGADD_FAILURE: "BOOKING_ADD_FAILURE",

    BOOKINGEDIT_REQUEST: "BOOKING_EDIT_REQUEST",
    BOOKINGEDIT_SUCCESS: "BOOKING_EDIT_SUCCESS",
    BOOKINGEDIT_FAILURE: "BOOKING_EDIT_FAILURE",

    GETHOSPITALADDRESS_REQUEST: "BOOKING_GETHOSPITALADDRESS_REQUEST",
    GETHOSPITALADDRESS_SUCCESS: "BOOKING_GETHOSPITALADDRESS_SUCCESS",
    GETHOSPITALADDRESS_FAILURE: "BOOKING_GETHOSPITALADDRESS_FAILURE",

    BOOKINGUPDATE_REQUEST: "BOOKING_UPDATE_REQUEST",
    BOOKINGUPDATE_SUCCESS: "BOOKING_UPDATE_SUCCESS",
    BOOKINGUPDATE_FAILURE: "BOOKING_UPDATE_FAILURE",

    BOOKINGSTATUSUPDATE_REQUEST: "BOOKING_STATUSUPDATE_REQUEST",
    BOOKINGSTATUSUPDATE_SUCCESS: "BOOKING_STATUSUPDATE_SUCCESS",
    BOOKINGSTATUSUPDATE_FAILURE: "BOOKING_STATUSUPDATE_FAILURE",

    GETALLBOOKINGS_REQUEST: "BOOKINGS_GETALL_REQUEST",
    GETALLBOOKINGS_SUCCESS: "BOOKINGS_GETALL_SUCCESS",
    GETALLBOOKINGS_FAILURE: "BOOKINGS_GETALL_FAILURE",

    DELETE_REQUEST: "BOOKINGS_DELETE_REQUEST",
    DELETE_SUCCESS: "BOOKINGS_DELETE_SUCCESS",
    DELETE_FAILURE: "BOOKINGS_DELETE_FAILURE",
    
    SETRETURN_REQUEST: "SET_RETURN_REQUEST",
    SETRETURN_SUCCESS: "SET_RETURN_SUCCESS",
    SETRETURN_FAILURE: "SET_RETURN_FAILURE"
};
