import React, { Component } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { setActions } from "../../../../actions";
import { setService } from "../../../../services";
import { history } from "../../../../helpers";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

class EditSet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      kitList: [],
      productList: [],
      selectedKit: "",
      selectedProductId: "",
      selectedProductList: [],
      quantity: "",
      // product_error: "",
      kit_error: "",
      searchProducts: "",
      error: {},
      setData: {
        id: "",
        set_name: "",
        description: "",
        kitID: "",
        productData: {
          products: [],
        },
        setDocs: "",
        setImgDocs: "",
      },
      pdfname: "",
      actual_name: "",
      loading: false,
      imagePreviewUrl: "", // for preview of new uploaded image
      isPreviewOpen: false, // to handle modal preview of image

      imageFile: null,
    };

    this.setEditSet = this.setEditSet.bind(this);
    this.handleOnKitSearch = this.handleOnKitSearch.bind(this);
    this.handleOnKitSelect = this.handleOnKitSelect.bind(this);
    this.handleOnProductSearch = this.handleOnProductSearch.bind(this);
    this.handleOnProductSelect = this.handleOnProductSelect.bind(this);
    this.handleKitList = this.handleKitList.bind(this);
    this.handleProductList = this.handleProductList.bind(this);
    this.handleProductQuantity = this.handleProductQuantity.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.updateSet = this.updateSet.bind(this);
    this.fileHandler = this.fileHandler.bind(this);
    this.imageHandler = this.imageHandler.bind(this);
    this.openPreview = this.openPreview.bind(this);
    this.closePreview = this.closePreview.bind(this);
  }

  imageHandler = (event) => {
    const { setData } = this.state;
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setData["setImgDocs"] = file; // store the file object
        this.setState({
          imageFile: file, // store file for form submission
          imagePreviewUrl: reader.result, // display new image preview
          setData,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  openPreview = () => {
    this.setState({ isPreviewOpen: true });
  };

  closePreview = () => {
    this.setState({ isPreviewOpen: false });
  };

  getSingleUser(id) {
    this.props.dispatch(setActions.getById(id));
  }

  setEditSet(data) {
    var productData = [];
    data.setProductModules.length > 0 &&
      data.setProductModules.map((item) => {
        var id = item.productID._id;
        var name = item.productID.name;
        var code = item.productID.code;
        var quantity = item.quantity;
        productData.push({
          _id: id,
          name: name,
          code: code,
          quantity: quantity,
        });
      });
    var kitData = [{ id: data.kitID._id, name: data.kitID.name }];
    console.log("aaayuu", data);
    this.setState({
      setData: {
        id: data._id,
        set_name: data.set_name,
        description: data.description,
        kitID: data?.kitID?._id ? data?.kitID?._id : "",
        kitName: data.kitID.name,
        productData: {
          products: productData,
        },
        setDocs: "",
        setImgDocs: data.setImgDocsName,
      },
      selectedProductList: productData,
      selectedKit: kitData,
      pdfname: data?.setDocument?.original_name
        ? data.setDocument?.original_name
        : "",
      actual_name: data?.setDocument?.original_name
        ? data.setDocument?.original_name
        : "",
    });
  }

  handleProductQuantity(quantity, index) {
    var selectedProductList = this.state.selectedProductList;
    selectedProductList[index].quantity = quantity;
    this.setState({
      selectedProductList: selectedProductList,
    });
  }

  handleSetData = (name, value) => {
    const { setData } = this.state;
    setData[name] = value;
    this.setState({
      setData,
    });
  };

  handleOnKitSearch(searchString, result) {
    console.log(result);
    const { setData } = this.state;
    setData["kitID"] = "";
    setData["selectedKit"] = "";
    this.setState({
      setData,
    });
    this.props.dispatch(setActions.getKitsList({ search: searchString }));
  }

  handleOnKitSelect(item) {
    this.setState({
      setData: {
        ...this.state.setData,
        kitID: item._id,
      },
      selectedKit: item,
    });
  }

  handleOnProductSearch(searchString, result) {
    console.log(result);
    this.setState({
      searchProducts: searchString,
    });
    this.props.dispatch(setActions.getProductsList({ search: searchString }));
  }

  fileHandler = (event) => {
    const { setData } = this.state;
    const file = event.target.files[0];
    setData["setDocs"] = file;
    this.setState({
      setData,
    });
  };

  handleOnProductSelect(item) {
    item.name = item.nameOld;
    var id = item._id;
    var newProduct;
    var index;
    if (this.state.selectedProductList.length > 0) {
      //newProduct = this.state.selectedProductList.filter(product => product._id === id);
      newProduct = this.state.selectedProductList.find(
        (product) => product._id === id
      );
      index = this.state.selectedProductList.findIndex(
        (product) => product._id === id
      );
    }
    if (newProduct) {
      newProduct.quantity = parseInt(newProduct.quantity) + 1;
      var list = this.state.selectedProductList;
      list[index] = newProduct;
      this.setState({
        selectedProductList: list,
      });
    } else {
      item.quantity = 1;
      this.setState({
        selectedProduct: item,
      });

      this.setState((prevState) => ({
        selectedProductList: [item, ...prevState.selectedProductList],
      }));
    }
    this.setState({
      searchProducts: "",
    });
  }

  handleKitList(kitList) {
    this.setState({
      kitList: kitList,
    });
  }

  handleProductList(productList) {
    productList.map((item) => {
      item.nameOld = item.name;
      item.name = item.name + " (" + item.code + ")";
    });
    this.setState({
      productList: productList,
    });
  }

  updateSet() {
    this.setState({ loading: true });
    // if(this.state.selectedProductList.length === 0) {
    //     this.setState({
    //         error: true,
    //         product_error: "Please select atleast one item"
    //     });
    // }
    // else
    // {
    // this.setState({
    //     product_error: ""
    // });
    // }
    // if(this.state.selectedProductList.length > 0 ) {
    var selectedProductList = this.state.selectedProductList;
    var tempProducts = [];

    selectedProductList.map((product) => {
      var tempObj = {};
      tempObj.id = product._id;
      tempObj.quantity = product.quantity;
      tempProducts.push(tempObj);
    });

    // var products = {
    //     products: tempProducts
    // };

    this.setState(
      {
        setData: {
          ...this.state.setData,
          productData: {
            products: tempProducts,
          },
        },
      },
      () => {
        //this.props.dispatch(setActions.updateSet(this.state.setData));

        let obj = {
          id: this.state.setData.id,
          set_name: this.state.setData.set_name,
          description: this.state.setData.description,
          kitID: this.state.setData.kitID,
          setDocs: this.state.setData.setDocs,
          productData: { products: this.state.setData.productData.products },
          selectedKit: this.state.setData.selectedKit,
          setImgDocs: this.state.setData.setImgDocs,
        };

        const formData = new FormData();
        formData.append("id", obj.id);
        formData.append("set_name", obj.set_name);
        formData.append("description", obj.description);
        formData.append("kitID", obj.kitID);
        formData.append("setDocs", obj.setDocs);
        formData.append("setImgDocs", obj.setImgDocs);
        formData.append("productData", JSON.stringify(obj.productData));
        //formData.append("selectedKit", obj.selectedKit);
        setService
          .updateSet(formData)
          .then((res) => {
            if (res.data.status === 200) {
              history.push("/admin/kits");
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            this.setState({ error: error.response.data.data.errors });
          });
      }
    );
    // }
  }

  handleRemoveImage = () => {
    this.setState({
      imagePreviewUrl: "",
      setData: {
        ...this.state.setData,
        setImgDocs: "",
      },
    });
  };

  deleteProduct(e, id) {
    e.preventDefault();
    const newProductList = this.state.selectedProductList.filter(
      (product) => product._id !== id
    );
    this.setState({ selectedProductList: newProductList });
  }

  componentDidMount() {
    this.getSingleUser(this.props.match.params.id);
    this.handleOnKitSearch("", "");
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.kitsList) {
      this.handleKitList(nextProps.kitsList);
    }
    if (nextProps.productsList) {
      this.handleProductList(nextProps.productsList);
    }

    if (nextProps.editSet == true) {
      this.setEditSet(nextProps.set);
    }

    if (nextProps.error !== "") {
      this.setState({
        error: true,
      });
    }
  }

  render() {
    const { redirect } = this.props;
    const { setData, error, pdfname, actual_name, loading, imagePreviewUrl } =
      this.state;
    console.log("ayushi", setData);
    // const imageUrl = imagePreviewUrl
    //     ? imagePreviewUrl
    //     : process.env.REACT_APP_APIURL + "assets/uploads/setImages/" + setData.setImgDocsName;
    if (redirect) {
      this.setState({ loading: false });
      return <Redirect to="/admin/kits" />;
    }
    if (!this.state.user) {
      return <Redirect to="/" />;
    }
    if (this.state.user.data.user_role !== "admin") {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <Navbar activePage="kits" />
        <section className="b-burger">
          <div className="container">
            <h1 className="date-scroll pt-4">
              {" "}
              <Link to="/admin/kits">
                <i className="fa fa-long-arrow-left smbtn"></i>{" "}
                <strong>Back</strong>
              </Link>
            </h1>

            <div className="row pt-4">
              <div className="col-lg-8 offset-lg-2">
                <div className="d-card">
                  <h4>Edit Set Details</h4>
                  <div className="form-group">
                    <label>Select Kit</label>
                    <ReactSearchAutocomplete
                      //className="form-control chosen-select"
                      items={this.state.kitList}
                      onSearch={this.handleOnKitSearch}
                      onSelect={this.handleOnKitSelect}
                      inputSearchString={this.state.setData.kitName}
                      fuseOptions={{ minMatchCharLength: 1 }}
                      inputDebounce="100"
                      autoFocus
                      placeholder="Search..."
                    />
                    {this.state.error && error ? (
                      <span className="text-danger">
                        {error.kitID ? error.kitID : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group">
                    <label>Set Name (Set Unique Identifier)</label>
                    <Input
                      type="text"
                      className="form-control"
                      value={this.state.setData.set_name}
                      onChange={(e) =>
                        this.handleSetData("set_name", e.target.value)
                      }
                    />
                    {this.state.error && error ? (
                      <span className="text-danger">
                        {error.set_name ? error.set_name : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group">
                    <label>Upload PDF (Maximum file size: 25 MB)</label>
                    <Input
                      id="setfile"
                      customLabel="Browse File"
                      name="file"
                      type="file"
                      accept=".pdf"
                      onChange={(e) => this.fileHandler(e)}
                    />
                    {this.state.error && error ? (
                      <span className="text-danger">
                        {error.setDocs ? error.setDocs : ""}
                      </span>
                    ) : (
                      ""
                    )}

                    {pdfname && !this.state.setData.setDocs && (
                      <span>
                        <a
                          href={
                            process.env.REACT_APP_APIURL +
                            "assets/uploads/kitDocuments/setDocs/" +
                            actual_name
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {pdfname}
                        </a>
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Upload Image</label>
                    <Input
                      id="setfile"
                      name="myImage"
                      type="file"
                      onChange={this.imageHandler}
                      accept="image/*"
                    />
                    {this.state.error && error ? (
                      <span className="text-danger">
                        {error.setImgDocs ? error.setImgDocs : ""}
                      </span>
                    ) : (
                      ""
                    )}
                    {imagePreviewUrl ? (
                      <div
                        onClick={this.openPreview}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={imagePreviewUrl}
                          style={{
                            marginTop: "2px",
                            width: "100px",
                            height: "60px",
                            objectFit: "cover",
                          }}
                          alt="Set Preview"
                        />
                      </div>
                    ) : (
                      this.state.setData.setImgDocs && (
                        <div
                          onClick={this.openPreview}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={`${process.env.REACT_APP_APIURL}assets/uploads/kitDocuments/item_used/${this.state.setData.setImgDocs}`}
                            style={{
                              marginTop: "2px",
                              width: "100px",
                              height: "60px",
                              objectFit: "cover",
                            }}
                            alt="Set Preview"
                          />
                        </div>
                      )
                    )}

                    {this.state.isPreviewOpen && (
                      <div
                        style={{
                          position: "fixed",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0,0,0,0.8)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={this.closePreview}
                      >
                        {imagePreviewUrl ? (
                          <img
                            src={imagePreviewUrl}
                            style={{ maxWidth: "90%", maxHeight: "90%" }}
                            alt="Preview"
                          />
                        ) : (
                          this.state.setData.setImgDocs && (
                            <img
                              src={`${process.env.REACT_APP_APIURL}assets/uploads/kitDocuments/item_used/${this.state.setData.setImgDocs}`}
                              style={{ maxWidth: "90%", maxHeight: "90%" }}
                              alt="Preview"
                            />
                          )
                        )}
                      </div>
                    )}
                  </div>
                  {/* <div className="form-group">
  <label>Upload Image</label>
  <Input
    id="setfile"
    name="myImage"
    type="file"
    onChange={this.imageHandler}
    accept="image/*"
  />
  {this.state.error && error ? (
    <span className="text-danger">
      {error.setImgDocs ? error.setImgDocs : ""}
    </span>
  ) : (
    ""
  )}

  {imagePreviewUrl || this.state.setData.setImgDocs ? (
    <div
      style={{
        position: "relative",
        width: "100px",
        height: "60px",
        cursor: "pointer",
      }}
    >
      <img
        src={
          imagePreviewUrl
            ? imagePreviewUrl
            : `${process.env.REACT_APP_APIURL}assets/uploads/kitDocuments/item_used/${this.state.setData.setImgDocs}`
        }
        style={{
          width: "100px",
          height: "60px",
          objectFit: "cover",
        }}
        alt="Set Preview"
        onClick={this.openPreview}
      />
      <span
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
          background: "red",
          color: "white",
          borderRadius: "50%",
          padding: "2px 6px",
          cursor: "pointer",
        }}
        onClick={() => this.handleRemoveImage()}
      >
        &times;
      </span>
    </div>
  ) : null}

  {this.state.isPreviewOpen && (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={this.closePreview}
    >
      {imagePreviewUrl ? (
        <img
          src={imagePreviewUrl}
          style={{ maxWidth: "90%", maxHeight: "90%" }}
          alt="Preview"
        />
      ) : (
        this.state.setData.setImgDocs && (
          <img
            src={`${process.env.REACT_APP_APIURL}assets/uploads/kitDocuments/item_used/${this.state.setData.setImgDocs}`}
            style={{ maxWidth: "90%", maxHeight: "90%" }}
            alt="Preview"
          />
        )
      )}
    </div>
  )}
</div> */}

                  <div className="form-group">
                    <label>Set Description</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      value={this.state.setData.description}
                      onChange={(e) =>
                        this.handleSetData("description", e.target.value)
                      }
                    ></textarea>
                    {this.state.error && error ? (
                      <span className="text-danger">
                        {error.description ? error.description : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mt-5 text-right">
                  <Link
                    to="/admin/kits"
                    className="btn btn-secondary mb-3 mr-2"
                  >
                    Cancel
                  </Link>

                  {loading == false && (
                    <button
                      className="btn btn-primary mb-3"
                      onClick={this.updateSet}
                    >
                      Save Changes
                    </button>
                  )}
                  {loading == true && (
                    <button
                      className="btn btn-primary loader-icon mb-3"
                      disabled
                    >
                      <Spinner className="img-svg"></Spinner>
                    </button>
                  )}
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { set, setDetails, editSet } = state.rootReducer.sets;
  const { kitsList, productsList, redirect } = state.rootReducer.sets;

  return {
    set,
    setDetails,
    editSet,
    kitsList,
    productsList,
    redirect,
  };
}

export default connect(mapStateToProps)(EditSet);
